.bg {
    &-primary {
        background: $background;
        color: $color;
    }
}

.btn {
    border: 0;
    &-primary {
        background: $background;
        color: $color;

        &:hover {
            background: $hoverBackground;
            color: $hoverColor;
        }
    }
}

.text {
    &-primary {
        background: $background;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
.bootstrap-tagsinput {
    border-color: #0b1526;
    border: 1px solid;
    overflow: auto;
    width: 100%;
}
